import { Checkout } from '../../containers/Checkout'
import { Register } from '../../containers/Register'
import { useAuth } from '../../hooks/useAuth'
import leftOnRegisterv2 from '../../assets/leftOnRegister_v1.png'

interface IProps {
  couponCode?: string
  couponComponent?: JSX.Element
  bannerImage?: string
  backgroundColor?: string
  titleOverride?: string
}

export const ChdiCheckout: React.FC<IProps> = ({
  couponCode,
  couponComponent,
  bannerImage = leftOnRegisterv2,
  backgroundColor = "var(--Bone)",
  titleOverride,
}) => {
  const { customer } = useAuth()

  return(
    <div className='container-fluid checkright' style={{ backgroundColor }}>
      <div className="row py-md-5 py-0 plan-section ">
        <div className='col-md-5 col-10 my-md-3 mx-auto text-center order-2 order-md-1'>
          <img loading="lazy" decoding="async" src={bannerImage} className="img-fluid pb-4" alt="banner" />
        </div>
        <div className='col-md-7 col-12 my-3 mx-auto order-1 order-md-2'>
        <div className="text-center mb-4">
        <h5 className='pt-5 pb-0' style={{ color: 'var(--Orange)' }}>LETS GET STARTED</h5>
          <h1 className='section- text-black'>Create an Account</h1>
          <p className='section-subtitle text-black'>Get unlimited DOGTV, ad free, anytime, anywhere.</p>
        </div>
        <div className="regform mb-4">
          {!customer?.jwt
            ? <Register couponComponent={couponComponent} />
            : <Checkout couponCode={couponCode} titleOverride={titleOverride} />
          }
        </div>
    </div>
        </div>
    </div>
  )
}