import { useContext } from 'react'
import { AuthContext } from '../context/Auth.context'

export const useAuth = () => {
  const auth = useContext(AuthContext)

  if (!auth) {
    throw new Error('Auth context was not provided.')
  }

  return auth
}
