import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const HubspotScriptLoader = () => {
  const location = useLocation();

  useEffect(() => {
    const loadHubspotScript = () => {
      if (!document.getElementById('hs-script-loader')) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.async = true;
        script.defer = true;
        script.src = '//js-na1.hs-scripts.com/22733430.js';
        document.body.appendChild(script);
        console.log('HubSpot script loaded');
      }
    };

    const removeHubspotScript = () => {
      const script = document.getElementById('hs-script-loader');
      if (script) {
        document.body.removeChild(script);
        console.log('HubSpot script removed');
      }
    };

    // Define the routes where the script should load
    const routesToLoadScript = ['/', '/petsmart', '/dogday', '/try' , '/privacy', '/success', '/terms'];

    if (routesToLoadScript.includes(location.pathname)) {
      loadHubspotScript();
    } else {
      removeHubspotScript();
    }

    if (location.pathname === '/checkout') {
      removeHubspotScript();
    }

    return () => {
      removeHubspotScript();
    };
  }, [location]);

  return null;
};

export default HubspotScriptLoader;
