//import React from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOffers } from '../../hooks/useOffers'
import { Zoom } from 'react-awesome-reveal'
import PhotoGallery from '../../components/PhotoGallery'
import DogdayFAQ from '../../components/DogdayFAQ'
import 'bootstrap/dist/css/bootstrap.min.css'
import nyt from '../../assets/nyt.svg'
import ellen from '../../assets/ellen.svg'
import forbes from '../../assets/forbes.svg'
import jimmy from '../../assets/jimmy.svg'
import usaToday from '../../assets/usaToday.svg'
import twp from '../../assets/twp.svg'
import guardian from '../../assets/guardian.svg'
import iosLogo from '../../assets/ios.svg'
import chromeLogo from '../../assets/chrome.svg'
import firetvLogo from '../../assets/firetv.svg'
import rokuLogo from '../../assets/roku.svg'
import androidLogo from '../../assets/android.svg'
import samsungLogo from '../../assets/samsung.svg'
import appletvLogo from '../../assets/appletv.svg'
import Redcheck from '../../assets/petsmart/redCheck.svg'
import BluePattern from '../../assets/petsmart/BluePattern.svg'
import RedPattern from '../../assets/petsmart/RedPattern.svg'
import TiltedHeart from '../../assets/petsmart/TiltedHeart.svg'
import RedPaws from '../../assets/petsmart/RedPaws.svg'
import faqdoggy from '../../assets/petsmart/faqDoggie.png'
import bone from '../../assets/petsmart/bone.svg'
import today from '../../assets/petsmart/today.svg'
import PetsmartHeroV2 from '../../assets/petsmart/PetsmartHeroV2.png'
import doggieBackedSection from '../../assets/petsmart/doggieBackedSectionv2.jpg'
import doggiePlanSection from '../../assets/petsmart/doggiePlanSectionv2.png'
import fbReviews from '../../assets/petsmart/fbReviews.png'
import couchv2 from '../../assets/petsmart/couchv3.svg'
import ribbonv2 from '../../assets/petsmart/ribbonv3.svg'
import blueCheck from '../../assets/petsmart/PetsmartCheck.svg'
import backdrop8th from '../../assets/petsmart/backdrop8thPetsmartv2.jpg'
import onTV from '../../assets/petsmart/backdrop8thPetsmart.jpg'
import dogtv_logo from '../../assets/ndd_dogtv.jpg'


export const DogdayHome: React.FC = () => {
  const navigate = useNavigate()
  const offset = window.innerWidth <= 768 ? 50 : 80
  const [isVisible, setIsVisible] = useState(false)
  const shapeDividerRef = useRef<HTMLDivElement | null>(null)
  const [isStickyVisible, setIsStickyVisible] = useState(true)

  useEffect(() => {
    document.title = "DOGTV | The Ultimate Streaming Platform for Dogs & Their Humans | DogDay";
  }, []);

  const onSelectOffer = () => {
    navigate('/dogday/checkout')
  }

  const handleClick = (path: string) => {
    navigate(path)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          if (shapeDividerRef.current) {
            observer.unobserve(shapeDividerRef.current)
          }
        }
      },
      { threshold: 0.1 },
    )

    if (shapeDividerRef.current) {
      observer.observe(shapeDividerRef.current)
    }

    return () => {
      if (shapeDividerRef.current) {
        observer.unobserve(shapeDividerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const anchors = document.querySelectorAll('a[href^="#"]')

    const handleAnchorClick = (e: Event) => {
      e.preventDefault()

      const targetId = (e.currentTarget as HTMLAnchorElement)
        .getAttribute('href')!
        .substring(1)
      const targetElement = document.getElementById(targetId)

      if (targetElement) {
        const elementPosition = targetElement.offsetTop
        const offsetPosition = elementPosition - offset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }

    anchors.forEach((anchor) =>
      anchor.addEventListener('click', handleAnchorClick as EventListener),
    )

    return () => {
      anchors.forEach((anchor) =>
        anchor.removeEventListener('click', handleAnchorClick as EventListener),
      )
    }
  }, [offset])

  useEffect(() => {
    const handleScroll = () => {
      const startNowSection = document.getElementById('start-now')
      const rect = startNowSection?.getBoundingClientRect()
      const isInView = rect
        ? rect.top <= window.innerHeight && rect.bottom >= 0
        : false

      setIsStickyVisible(!isInView)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      {/* hero section - 1st*/}
      <div
        className="container-fluid section-with-diagonal hero-background"
        style={{
          background: 'var(--White)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          zIndex: '1000',
        }}
      >
        <div className="row px-mobile pt-5 pb-3">
          <div className="col-md-6 d-flex align-items-center text-black pt-3 pt-md-5">
            <div className="d-flex flex-column">
              <img
                loading="lazy"
                decoding="async"
                width="254"
                height="106"
                src={dogtv_logo}
                className="img-fluid animated-image mx-auto-mobile"
                alt="landing-hero-v1" 
              />
              <h3 className="text-center text-md-start py-4">
              Celebrating National Dog Day!  
Get 1 month FREE of The Ultimate Streaming Platform for Dogs & Their Humans
              </h3>
              <ul className="checklist">
                <li
                  style={{
                    backgroundImage: `url(${Redcheck})`,
                    fontWeight: 'bold',
                    color: 'var(--PetSmart-Red)',
                  }}
                >
                  1 Month Free of DOGTV
                </li>
                <li
                  style={{
                    backgroundImage: `url(${blueCheck})`,
                    fontWeight: 'bold',
                  }}
                >
                  Developed for Dogs & Humans
                </li>
                <li
                  style={{
                    backgroundImage: `url(${blueCheck})`,
                    fontWeight: 'bold',
                  }}
                >
                  Unlimited Access
                </li>
                <li
                  style={{
                    backgroundImage: `url(${blueCheck})`,
                    fontWeight: 'bold',
                  }}
                >
                  Always Ad Free
                </li>
                <li
                  style={{
                    backgroundImage: `url(${blueCheck})`,
                    fontWeight: 'bold',
                  }}
                >
                  Stream Anytime, Anywhere
                </li>
                <li
                  style={{
                    backgroundImage: `url(${blueCheck})`,
                    fontWeight: 'bold',
                  }}
                >
                  No Contract, Cancel Anytime
                </li>
                <li
                  style={{
                    backgroundImage: `url(${blueCheck})`,
                    fontWeight: 'bold',
                  }}
                >
                  Trusted by Dog Owners Worldwide
                </li>
              </ul>
              <button
                      className="btn btn-primary d-flex mt-auto mx-auto-mobile"
                      onClick={(e) => {
                        e.stopPropagation()
                        onSelectOffer()
                      }}
                    >
                      Start 1 Month Free Trial
                    </button>
              <p
                className="py-2 text-md-start text-center"
                style={{ fontSize: '12px', maxWidth: '520px' }}
              >
                You will be charged $0.00 per month for the promotional period
                (1 month). After the promotion ends, you will be charged $9.99
                per month, plus applicable taxes, on a recurring basis until you
                cancel.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-center d-flex align-items-center justify-content-center pt-3 pt-md-5">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="1024"
                height="996"
                src={PetsmartHeroV2}
                className="img-fluid animated-image floating w-75"
                alt="landing-hero-v1"
              />
            </Zoom>
          </div>
        </div>
      </div>
      {/* end of hero section - 1st */}
      {/* as seen on section - 2nd */}
      <div className="container-fluid" style={{ background: 'var(--White)' }}>
        <div className="row text-center pt-5 logos-section px-mobile">
          <div className="col-12">
            <h5 className="py-3" style={{ color: 'var(--PetSmart-Red)' }}>
              As Seen On
            </h5>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="180"
                height="125"
                src={nyt}
                className="img-fluid zoom-img"
                alt="nyt"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="180"
                height="125"
                src={ellen}
                className="img-fluid zoom-img"
                alt="ellen"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="180"
                height="125"
                src={forbes}
                className="img-fluid zoom-img"
                alt="forbes"
              />
            </Zoom>
          </div>
          <div className="col-md-1 col-3 my-3 hide">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="180"
                height="125"
                src={jimmy}
                className="img-fluid zoom-img"
                alt="jimmy"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="180"
                height="125"
                src={usaToday}
                className="img-fluid zoom-img"
                alt="usaToday"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="180"
                height="125"
                src={twp}
                className="img-fluid zoom-img"
                alt="twp"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="180"
                height="125"
                src={guardian}
                className="img-fluid zoom-img"
                alt="guardian"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="180"
                height="125"
                src={today}
                className="img-fluid zoom-img"
                alt="today"
              />
            </Zoom>
          </div>
        </div>
        <div
          className={`custom-shape-divider-bottom shape-divider-animation ${
            isVisible ? 'animate-shape' : ''
          }`}
          ref={shapeDividerRef}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0V61H0L1200 0z"
              style={{ fill: 'var(--PetSmart-Red)' }}
            ></path>
          </svg>
        </div>
      </div>
      {/* end of as seen on section - 2nd */}
      {/* what's inside section - 3rd */}
      <div
        className="container-fluid "
        style={{
          background: 'var(--PetSmart-Red)',
          backgroundImage: `url(${RedPaws})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="row px-mobile">
          <div className="col-12 text-center mb-4 text-light">
            <h5 className="pt-5 pb-3" style={{ color: 'var(--White)' }}>
              WHAT'S INSIDE?
            </h5>
            <h2 className=" ">Features You'll Love</h2>
          </div>
          <div className="col-12 text-center">
            <div className="row text-light mb-5">
              <div className="col-md-4 col-10 my-3 mx-auto">
                <Zoom triggerOnce>
                  <img
                    loading="lazy"
                    decoding="async"
                    src={couchv2}
                    className="img-fluid pb-4 zoom-img"
                    alt="couchv2"
                  />
                </Zoom>
                <h4 className="pb-3 px-md-3">
                  Unlimited Shows, Music, and Dogumentaries
                </h4>
                <p className="px-md-3">
                  Discover an endless selection of shows, music, and
                  documentaries tailored to you and your dog's preferences and
                  needs.
                </p>
              </div>
              <div className="col-md-4 col-10 my-3 mx-auto">
                <Zoom triggerOnce>
                  <img
                    loading="lazy"
                    decoding="async"
                    src={bone}
                    className="img-fluid pb-4 zoom-img"
                    alt="bone"
                  />
                </Zoom>
                <h4 className="pb-3 px-md-3">
                  Expert-Designed and Approved Content
                </h4>
                <p className="px-md-3">
                  Our content is designed and developed by seasoned directors,
                  pet experts and renowned veterinarians to stimulate both you
                  and your dog, providing the ultimate enrichment experience.
                </p>
              </div>
              <div className="col-md-4 col-10 my-3 mx-auto">
                <Zoom triggerOnce>
                  <img
                    loading="lazy"
                    decoding="async"
                    src={ribbonv2}
                    className="img-fluid pb-4 zoom-img"
                    alt="ribbonv2"
                  />
                </Zoom>
                <h4 className="pb-3 px-md-3">Completely Ad-Free Viewing</h4>
                <p className="px-md-3">
                  Enjoy DOGTV on all your devices, with unlimited 24/7 streaming
                  that is always ad-free.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of what's inside section - 3rd */}
      {/* hero section - 3.5 */}
      <div
        className="container-fluid section-with-diagonal hero-background"
        style={{
          background: 'var(--White)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="row px-mobile pt-5 pb-3">
          <div className="col-md-6 d-flex align-items-center text-black pt-3 pt-md-5">
            <div className="d-flex flex-column">
              <h2
                className="text-center text-md-start py-4 tiltedheart"
                style={{ backgroundImage: `url(${TiltedHeart})` }}
              >
                Led by Love. <br />
                Backed by Science.
              </h2>
              <p className="py-2 text-md-start text-black text-center">
                Whether home or away, turn on DOGTV to keep them relaxed and
                happy
              </p>
              {/* 2x2 div here */}
              <div className="row pb-3 text-start text-black">
                <div className="col-md-6 pb-3" style={{ paddingLeft: '0' }}>
                  <strong style={{ color: 'var(--PetSmart-Blue)' }}>
                    Relaxation Programs
                  </strong>
                  <br />
                  Calming scenes and soothing music help ease separation
                  anxiety.
                </div>
                <div className="col-md-6 pb-3" style={{ paddingLeft: '0' }}>
                  <strong style={{ color: 'var(--PetSmart-Blue)' }}>
                    Exposure Programs
                  </strong>
                  <br />
                  Gently accustom your dogs to noises like doorbells and vacuum
                  cleaners.
                </div>
                <div className="col-md-6 pb-3" style={{ paddingLeft: '0' }}>
                  <strong style={{ color: 'var(--PetSmart-Blue)' }}>
                    Stimulation Programs
                  </strong>
                  <br />
                  Helps prevent boredom, great for puppies and senior dogs
                  offering animated sequences, dogs and other animals.
                </div>
                <div className="col-md-6 pb-3" style={{ paddingLeft: '0' }}>
                  <strong style={{ color: 'var(--PetSmart-Blue)' }}>
                    24/7 Livestream
                  </strong>
                  <br />
                  24-hour programming takes your dog through relaxation,
                  stimulation, exposure content you can turn on when you leave
                  the home.
                </div>
              </div>
              <button
                className="btn btn-primary d-flex mt-auto mx-auto-mobile"
                onClick={(e) => {
                  e.stopPropagation()
                  onSelectOffer()
                }}
              >
                Start 1 Month Free Trial
              </button>
            </div>
          </div>
          <div className="col-md-6 text-center d-flex align-items-center justify-content-center pt-3 pt-md-5">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="1024"
                height="996"
                src={doggieBackedSection}
                className="img-fluid w-75"
                alt="doggieBackedSection"
              />
            </Zoom>
          </div>
        </div>
        <div
          className={`custom-shape-divider-bottom shape-divider-animation ${
            isVisible ? 'animate-shape' : ''
          }`}
          ref={shapeDividerRef}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path d="M1200 0V61H0L1200 0z" className="shape-fill"></path>
          </svg>
        </div>
      </div>
      {/* end of hero section - 3.5 */}
      {/* What's Playing section - 4th */}
      <div
        className="container-fluid section-with-diagonal pb-4"
        style={{ background: 'var(--Black)' }}
      >
        <div className="row d-flex align-items-end pt-5 pb-4 px-mobile">
          <div className="col-md-9 text-md-start text-center align-items-end text-light pb-3 pb-md-0">
            <h2>What's Playing?</h2>
            <p className="py-2 fs-6">
              Binge-Worthy Award-Winning Content, Anytime, Anywhere.{' '}
              <strong>Because Your Dog Deserves the Best.</strong>
            </p>
          </div>
          <div className="col-md-3 text-md-end d-flex align-items-center align-items-md-end text-light">
          <button
            className="btn btn-primary d-flex mt-auto mx-auto-mobile"
            onClick={(e) => {
              e.stopPropagation()
              onSelectOffer()
            }}
          >
            Start 1 Month Free Trial
          </button>
          </div>
        </div>
        <div className="row stretched text-light">
          <div className="col-12 mb-4">
            <PhotoGallery />
          </div>
        </div>
        <div
          className={`hide custom-shape-divider-bottom shape-divider-animation ${
            isVisible ? 'animate-shape' : ''
          }`}
          ref={shapeDividerRef}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path d="M1200 0V61H0L1200 0z" className="shape-fill"></path>
          </svg>
        </div>
      </div>
      {/* end of What's Playing section - 4th */}
      {/* app logos section - 6th */}
      <div className="container-fluid " style={{ background: '#ffffff' }}>
        <div className="row text-center pt-5 logos-section px-mobile">
          <div className="col-12">
            <h5 className="py-3" style={{ color: 'var(--PetSmart-Red)' }}>
              Available anywhere, anytime.
            </h5>
            <h2 className="pb-3 mx-auto text-black">
              Unlimited premium content for dog lovers and their 4-legged best
              friends.
            </h2>
            <button
            className="btn btn-primary d-flex align-items-center justify-content-center mx-auto"
            onClick={(e) => {
              e.stopPropagation()
              onSelectOffer()
            }}
          >
            Start 1 Month Free Trial
          </button>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="120"
                height="125"
                src={iosLogo}
                className="img-fluid zoom-img"
                alt="ios"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="120"
                height="125"
                src={chromeLogo}
                className="img-fluid zoom-img"
                alt="chrome"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="120"
                height="125"
                src={firetvLogo}
                className="img-fluid zoom-img"
                alt="firetv"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="120"
                height="125"
                src={rokuLogo}
                className="img-fluid zoom-img"
                alt="roku"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="120"
                height="125"
                src={androidLogo}
                className="img-fluid zoom-img"
                alt="android"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="120"
                height="125"
                src={samsungLogo}
                className="img-fluid zoom-img"
                alt="samsung-smart-tv"
              />
            </Zoom>
          </div>
          <div className="col-md-2 col-3 my-3">
            <Zoom triggerOnce>
              <img
                loading="lazy"
                decoding="async"
                width="120"
                height="125"
                src={appletvLogo}
                className="img-fluid zoom-img"
                alt="apple-tv"
              />
            </Zoom>
          </div>
        </div>
      </div>
      {/* end of app logos section - 6th */}
      {/* TV section - 7th */}
      <div
        className="container-fluid withbgimage"
        style={{
          backgroundImage: `url(${backdrop8th})`,
          backgroundColor: 'var(--White)',
        }}
      >
        <div className="row pb-5">
          <div className="col-md-9 col-12 text-center mx-auto">
          <img
              loading="lazy"
              decoding="async"
              width="1920"
              height="1080"
              src={onTV}
              className="img-fluid shadow-img"
              alt="onTV"
            />
          </div>
        </div>
      </div>
      {/* end of TV section - 7th */}
      {/* Pricing section - 8th 
      <div
        id="start-now"
        className="container-fluid withbgimage nobgimagemobile nostickybtn"
        style={{
          backgroundImage: `url(${RedPattern})`,
          backgroundColor: 'var(--PetSmart-Red)',
          backgroundSize: 'contain',
        }}
      >
        <div className="row my-4 plan-section px-mobile">
          <div className="text-center mb-4">
            <h5 className="py-1" style={{ color: 'var(--White)' }}>
              Get 2 Months of DOGTV FREE
            </h5>
            <h1 className="section-title text-light">Start Your Trial</h1>
            <hr className="spacer" />
          </div>
            <div className="row px-md-1 px-0">
              <div
                className="col-md-8 mb-4 d-flex align-items-center"
              >
                  <img
                loading="lazy"
                decoding="async"
                width="2560"
                height="1457"
                src={doggiePlanSection}
                className="img-fluid"
                alt="doggiePlanSection"
              />
              </div>
              <div
                className="col-md-4 mb-4 d-flex align-items-center"
              >
                <div
                  className={`card text-left select-offer offer-0`}
                  onClick={() => onSelectOffer()}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="card-body d-flex flex-column">
                    <h5
                      className="card-title pb-3"
                      style={{ color: 'var(--PetSmart-Blue)' }}
                    >
                      special deal
                    </h5>
                    <h6
                      className="card-text price-text text-black"
                      style={{ fontSize: '72px' }}
                    >
                      Free!
                    </h6>
                    <hr className="h-40 hide" />
                    <p>Includes:</p>
                    <ul className="planlist">
                      <li
                        style={{
                          backgroundImage: `url(${Redcheck})`,
                          color: 'var(--PetSmart-Red)',
                        }}
                      >
                        2 Months Free, Then $9.99/month
                      </li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>
                        Unlimited Access
                      </li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>
                        Always Ad Free
                      </li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>
                        Stream Anytime, Anywhere
                      </li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>
                        No Contract, Cancel Anytime
                      </li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>
                        Global Dog Loving Community
                      </li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>
                        Tips from Veterinarians & Pet Experts
                      </li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>
                        Trusted by Dog Owners Worldwide
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary d-flex mt-auto"
                      onClick={(e) => {
                        e.stopPropagation()
                        onSelectOffer()
                      }}
                    >
                      Start 1 Month Free Trial
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      {/* end of Pricing section - 8th */}
        {/* FAQs section - 9th */}
        <div
          className="container-fluid section-with-diagonal nobgimagemobile"
          style={{
            background: `var(--PetSmart-Blue)`,
            backgroundImage: `url(${BluePattern})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="row pt-3 text-light text-center px-mobile">
            <div className="col-12 pt-5 mb-4">
              <h5 className="py-3">QUESTIONS?</h5>
              <h2>Frequently Asked Questions</h2>
            </div>
          </div>
          <div className="row text-light px-mobile">
            <div className="col-md-8 col-12 mb-4 mx-auto">
              <DogdayFAQ />
            </div>
            <div className="col-md-12 col-8 mx-auto text-center ">
              <Zoom triggerOnce>
                <img
                  loading="lazy"
                  decoding="async"
                  width="360"
                  height="391"
                  src={fbReviews}
                  className="img-fluid zoom-img"
                  alt="fbReviews"
                />
              </Zoom>
            </div>
            <div className="col-12 text-center">
              <img
                loading="lazy"
                decoding="async"
                width="488"
                height="391"
                src={faqdoggy}
                className="img-fluid"
                alt="faqdoggy"
              />
            </div>
          </div>
        </div>
    </>
  )
}
