import React from 'react';

export const Privacy: React.FC = () => {
  return (
    <div className="container py-5" >
      <div className='row text-black px-mobile'>
        <div className="col-md-8 col-12 mb-4 mx-auto">
          <h2>Privacy Policy</h2>
          <p>This <strong>Privacy Policy</strong> describes the information DOGTV ("<strong>DOGTV</strong>", "<strong>we</strong>", "<strong>our</strong>", or "<strong>us</strong>") collects, how we use this information, with whom we share it, and the choices you have in connection with this.</p>
          <h4>APPLICABILITY</h4>
          <p>This Privacy Policy applies to the DOGTV websites (dogtv.com and watch.dogtv.com), any websites that link to this policy, the DOGTV mobile application, and the content services we provide (the "<strong>Services</strong>").</p>
          <h4>INFORMATION COLLECTION AND USE</h4>
          <p>Through your use of our Services, we will collect personal information, which is information that identifies you or relates to you as an identifiable individual. We collect personal information as outlined below.</p>
          <h5>1. Information Collected From You</h5>
          <p>When you engage with the Services, we will collect your personal information. Except as otherwise indicated, the personal information we collect is such that we need to carry out the requested action. If you do not provide us with your personal information, we would not be able to do so. We collect personal information when you:</p>
          <ul>
            <li><strong>Contact us:</strong> When you contact us, we collect your name, email address, and any other information you choose to include in your correspondence. We use this personal information to respond to your questions or inquiries, troubleshoot where necessary, and address any issues you may have. The legal basis for this is performance of our contract with you.</li>
            <li><strong>Create an account:</strong> To create your DOGTV account, we will collect your name, email address, selected password, your location, and type of subscription. We also note whether you have signed up on the mobile app or on the website. We use this personal information to create and maintain your account, to communicate with you, and to send you invoices. The legal basis for this is performance of our contract with you. With your consent, we will also use this information to send you transactional and marketing communications.</li>
            <li><strong>Gift DOGTV:</strong> When you gift DOGTV, we will collect your email, the recipient’s email, and your payment information (card number and expiration date). We use this personal information to process your payment and facilitate your gift. The legal basis for this is performance of our contract with you.</li>
            <li><strong>Participate in surveys or promotions:</strong> When you participate in a survey, we will collect your name, email address, and social media username. We use this personal information to assess the survey and improve the Services. The legal basis for this processing is performance of our contract with you and our legitimate interest in the improvement of our Services.</li>
            <li><strong>Purchase a subscription:</strong> When you purchase a subscription, we will collect your payment information (card number and expiration date). We use this personal information to process your payment and facilitate your access to DOGTV. The legal basis for this is performance of our contract with you.</li>
            <li><strong>Subscribe to marketing emails:</strong> If you subscribe to our marketing emails, we will collect your email address to fulfill your request to receive information we feel may be of interest to you. The legal basis for this is your consent. We use a service provider to segment users and send them materials that are more tailored to their preferences. Please note we use Hubspot for email marketing services and our emails contain tracking technologies to gain valuable insight into behavior of recipients. With the help of these technologies, we can analyze whether a predefined action took place by a recipient, such as opening our emails, in order to better adapt and distribute our emails. When you subscribe to marketing emails, you are opting in to the use of these technologies. You can disable tracking by disabling the display of images by default in your email program.</li>
          </ul>
          <p>DOGTV may also use the personal information collected for the above purposes for benchmarking purposes, to comply with the law and for other limited circumstances as described in <strong>INFORMATION SHARING</strong>.</p>
          <h5>2. Information Collected from Third Parties</h5>
          <p>When you click on a banner that takes you to our site and create an account, we receive information about the website from which you were directed to our website.</p>
          <h5>Information Collected Automatically</h5>
          <p>In addition to the personal information you provide, we also collect information automatically via cookies as you use the Services. This information includes:</p>
          <ul>
            <li><strong>Usage information:</strong> We use essential, performance, analytics, and advertising cookies to collect information about your interaction with the Services, such as the pages you visit, the frequency of access, how much time you spend on each page, what you click on, and referring Services address. We use this personal information to: (i) conduct analytics; (ii) enhance user experience; (iii) provide you with targeted online advertising and measure the effectiveness of our advertising; (iv) prevent fraudulent use of the Services; and (v) diagnose and repair errors.</li>
            <li><strong>Device information:</strong> We use essential, performance, analytics, and advertising cookies to collect certain information about the device you use to access our Services, such as browser type, browser language, hardware model, operating system, and your preferences. We use this personal information to: (i) conduct analytics; (ii) enhance user experience; (iii) provide you with targeted online advertising and measure the effectiveness of our advertising; (iv) prevent fraudulent use of the Services; and (v) diagnose and repair errors.</li>
            <li><strong>Location information:</strong> We use essential, performance, analytics, and advertising cookies to collect information about your location, which may be determined through your IP address. We use this personal information to: (i) conduct analytics; (ii) enhance user experience; (iii) provide you with targeted online advertising and measure the effectiveness of our advertising; (iv) prevent fraudulent use of the Services; and (v) diagnose and repair errors.</li>
          </ul>
          <p>In general, to disable cookies and limit the collection and use of information through them, you can set your browser to refuse cookies or indicate when a cookie is being sent. To opt-out of interest-based advertising generally or to learn more, you can visit the <a style={{color:'var(--Orange)', fontWeight: 'bold'}} href="https://thenai.org/opt-out/" target="_blank" rel="noopener noreferrer">Network Advertising Initiative</a> or the <a style={{color:'var(--Orange)', fontWeight: 'bold'}} href="https://optout.aboutads.info/?c=2&lang=EN" target="_blank" rel="noopener noreferrer">Digital Advertising Alliance</a>.</p>
          <h4>INFORMATION SHARING</h4>
          <p>DOGTV will use the personal information identified in this Privacy Policy in the following instances:</p>
          <ul>
            <li><strong>With service providers:</strong> We share your personal information with our service providers that assist us in providing the Services, such as our IT support, payment processor, website host, analytics providers, marketing providers, communications providers, and survey providers. The legal basis for this is our legitimate interest in providing the Services more efficiently.</li>
            <li><strong>Within DOGTV:</strong> Where necessary, we share your personal information within DOGTV for legitimate business purposes in order to efficiently carry out our business and to the extent permitted by law. The legal basis for this is our legitimate interest in carrying out our business operations efficiently.</li>
            <li><strong>With marketing partners:</strong> If you have opted in, we will share your contact information with partners for marketing purposes. The legal basis for this is your consent.</li>
            <li><strong>In the event of a corporate reorganization:</strong> In the event that we enter into, or intend to enter into, a transaction that alters the structure of our organization, such as a reorganization, merger, acquisition, sale, joint venture, assignment, consolidation, transfer, change of control, or other disposition of all or any portion of our assets, we would share your personal information with third parties, including the buyer or target (and their agents and advisors) for the purpose of facilitating and completing the transaction. We would also share your personal information with third parties if we undergo bankruptcy or liquidation, in the course of such proceedings. The legal basis for this is our legitimate interest in carrying out our business operations.</li>
            <li><strong>For legal purposes:</strong> We will share your personal information where we are legally required to do so, such as in response to court orders, law enforcement or legal process, including for national security purposes; to establish, protect, or exercise our legal rights, as required to enforce our terms of use or other contracts; to defend against legal claims or demands; to detect, investigate, prevent, or take action against illegal activities, fraud, or situations involving potential threats to the rights, property, or personal safety of any person; or to comply with the requirements of any applicable law. The legal basis for this is compliance the law, compliance with legal obligations, and our legitimate interest in the protection of the rights of others.</li>
            <li><strong>With your consent:</strong> Apart from the reasons identified above, we may request your permission to share your personal information for a specific purpose. We will notify you and request consent before you provide the personal information or before the personal information you have already provided is shared for such purpose. You may revoke your consent at any time.</li>
          </ul>
          <h4>RIGHTS OF INDIVIDUALS IN THE EUROPEAN UNION</h4>
          <p>Individuals in the European Union are entitled to certain rights under the General Data Protection Regulation (GDPR). If our processing of your personal information is subject to the GDPR, you may be entitled to the following rights:</p>
          <ul>
            <li><strong>Right to access:</strong> You have the right to ask us for copies of your personal information. This right has some exemptions, which means you may not always receive all the personal information we process.</li>
            <li><strong>Right to rectification:</strong> You have the right to ask us to rectify personal information you think is inaccurate or incomplete.</li>
            <li><strong>Right to erasure:</strong> You have the right to ask us to erase your personal information in certain circumstances.</li>
            <li><strong>Right to restrict processing:</strong> You have the right to ask us to restrict the processing of your personal information in certain circumstances.</li>
            <li><strong>Right to object to processing:</strong> You have the right to object at any time, for reasons arising from your particular situation, to processing of your personal information, which is carried out on the basis of our legitimate interests.</li>
            <li><strong>Right to data portability:</strong> You have the right to ask that we transfer the personal information you gave us from one organization to another, or give it to you. Please note this only applies to personal information you have given us.</li>
            <li><strong>Right to lodge a complaint:</strong> You have the right to lodge a complaint with the relevant Supervisory Authority.</li>
          </ul>
          <p>To exercise these rights, please contact us at <strong>support@dogtv.com</strong>.</p>
          <h4>NEVADA RESIDENTS</h4>
          <p>If you are a consumer in the State of Nevada, you may request to opt-out of the current or future sale of your personal information. We do not currently sell any of your personal information under Nevada law, nor do we plan to do so in the future. However, you can submit a request to opt-out of future sales by contacting us at <strong><a style={{color:'var(--Orange)', fontWeight: 'bold'}} href="mailto:support@dogtv.com" rel="noopener noreferrer">support@dogtv.com</a></strong>. Please include "Opt-Out Request Under Nevada Law" in the subject line of your message.</p>
          <h4>CORRECT OR VIEW YOUR PERSONAL INFORMATION</h4>
          <p>You may access your DOGTV account to correct or view certain personal information of yours in our possession and which is associated with your account.</p>
          <h4>MARKETING EMAILS</h4>
          <p>You may opt-out of receiving marketing emails from us by clicking the "unsubscribe" link provided with each email. Please note that we will continue to send you emails necessary to the Services, your account, and any assistance you request.</p>
          <h4>MOBILE APPLICATION CHOICES</h4>
          <p>You have the following choices with respect to the DogTV mobile application:</p>
          <ul>
            <li><strong>Opt-out of push notifications:</strong> If you have enabled push notifications on the mobile application, you may disable these at any time by updating your device settings.</li>
            <li><strong>Turn off location settings:</strong> You may prevent your mobile device from sharing your location data by adjusting the permissions on your mobile device or within the mobile application.</li>
            <li><strong>Uninstall the mobile application:</strong> You can stop all further collection of your information by the mobile application by uninstalling the mobile application.</li>
          </ul>
          <h4>RETENTION OF PERSONAL INFORMATION</h4>
          <p>We will retain your personal information until the earlier of: (i) the personal information is no longer necessary to accomplish the purpose for which it was provided; or (ii) we delete your information pursuant to your request. We may retain your personal information for longer periods for specific purposes to the extent that we are obliged to do so in accordance with applicable laws and regulations and/or as necessary to protect our legal rights or for certain business requirements. This includes for security, fraud, and abuse prevention, for financial record-keeping, or to comply with legal or regulatory requirements.</p>
          <h4>DO NOT TRACK</h4>
          <p>We do not support Do Not Track (DNT). Do Not Track is a preference you can set to inform websites that you do not want to be tracked.</p>
          <h4>INFORMATION SECURITY</h4>
          <p>We implement and maintain reasonable security measures to protect the personal information we collect and maintain. Our security includes physical, administrative, and technology-based measures, such as encryption, access controls, firewalls, and physical security measures. However, no security measure or modality of data transmission over the Internet is 100% secure and we are unable to guarantee the absolute security of the information we have collected from you.</p>
          <h4>AGE RESTRICTION</h4>
          <p>The Services are not intended for individuals under the age of eighteen (18). If we learn that we have collected or received personal information from an individual under the age of eighteen (18), we will delete that information. If you believe we might have information from or about an individual under the age of eighteen (18), please contact us at <a style={{color:'var(--Orange)', fontWeight: 'bold'}} href="mailto:support@dogtv.com" rel="noopener noreferrer">support@dogtv.com</a>.</p>
          <h4>CHANGES TO THIS PRIVACY POLICY</h4>
          <p>We may change this Privacy Policy from time to time. We will post the changes to this page and will indicate the date the changes go into effect. We encourage you to review our Privacy Policy to stay informed. If we make changes that materially affect your privacy rights, we will notify you with a prominent post on the Services or via email, and obtain your consent, if required.</p>
          <h4>CONTACT</h4>
          <p>If you have any questions about this Privacy Policy, please contact us at <a style={{color:'var(--Orange)', fontWeight: 'bold'}} href="mailto:support@dogtv.com" rel="noopener noreferrer">support@dogtv.com</a>.</p>
          <p><strong><em>This Privacy Policy was last updated on October 27, 2021</em></strong></p>
        </div>
      </div>
    </div>
  );
};
