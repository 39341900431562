import { createContext, PropsWithChildren, useState } from 'react'

interface IAuthContext {
  customer?: ICustomer
  setCustomer: React.Dispatch<React.SetStateAction<ICustomer | undefined>>
  locale: ILocale
  setLocale: React.Dispatch<React.SetStateAction<ILocale>>
  userData: Record<string, any>
  setUserData: React.Dispatch<React.SetStateAction<Record<string, any>>>
}

export const AuthContext = createContext<IAuthContext | null>(null)

export const AuthContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [customer, setCustomer] = useState<ICustomer>()
  const [locale, setLocale] = useState<ILocale>({
    country: 'US',
    currency: 'USD',
    locale: 'en US',
  })
  const [userData, setUserData] = useState<Record<string, any>>({})

  return (
    <AuthContext.Provider
      value={{
        customer,
        setCustomer,
        locale,
        setLocale,
        userData,
        setUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
