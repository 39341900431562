import React from 'react';
import Term from '../components/Term'

export const Terms: React.FC = () => {
  return (
    <div id='terms' className="container py-5">
      <div className='row text-black px-mobile'>
        <div className="col-md-8 col-12 mb-4 mx-auto">
          <h2>Terms of Use</h2>
          <p>Welcome to dogtv.com (together with its Content and services, the "Site") which is operated by PTV Media Ltd ("DOGTV"). Please read the following Terms of Use ("Terms") carefully before using this Site so that you are aware of your legal rights and obligations with respect to DOGTV.</p>
          <Term />
        </div>
      </div>
    </div>
  );
};
