import React, { useState } from 'react';

interface AccordionItem {
  title: string;
  content: string;
}

interface SVGProps {
  className?: string;
}

const ChevUp: React.FC<SVGProps> = ({ className }) => (
  <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M2.0293 11.4854L10.5146 3.00007L18.9999 11.4853" stroke="currentColor" strokeWidth="3"/>
  </svg>
);

const ChevDown: React.FC<SVGProps> = ({ className }) => (
  <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M19 1.5L10.5147 9.98528L2.02944 1.5" stroke="currentColor" strokeWidth="3"/>
  </svg>
);

const Accordion: React.FC<{ items: AccordionItem[] }> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className={`accordion-header ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleClick(index)}
          >
            <h6>{item.title}</h6>
            <span className="chevron-icon">
              {activeIndex === index ? <ChevUp /> : <ChevDown />}
            </span>
          </div>
          <div className={`accordion-content ${activeIndex === index ? 'active' : ''}`}>
            <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
