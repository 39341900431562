import { useContext } from 'react'
import { OffersContext } from '../context/Offers.context'

export const useOffers = () => {
  const context = useContext(OffersContext)

  if (!context) {
    throw new Error('Offers context was not provided.')
  }

  const {
    isPickOfferFirstFlow,
    offers,
    offersLoading,
    selectedOffer,
    setIsPickOfferFirstFlow,
    setSelectedOffer,
  } = context

  return {
    isPickOfferFirstFlow,
    offers,
    offersLoading,
    selectedOffer,
    setIsPickOfferFirstFlow,
    setSelectedOffer,
  }
}
