import React from 'react'

// Import your images
import image1 from '../assets/photos/v184_1517.jpg'
import image2 from '../assets/photos/v184_1518.jpg'
import image3 from '../assets/photos/v184_1519.jpg'
import image4 from '../assets/photos/v184_1522.jpg'
import image5 from '../assets/photos/v184_1524.jpg'
import image6 from '../assets/photos/v184_1525.jpg'
import image7 from '../assets/photos/v184_1527.jpg'
import image8 from '../assets/photos/v184_1528.jpg'
import image9 from '../assets/photos/v184_1529.jpg'
import image10 from '../assets/photos/v184_1530.jpg'
import image11 from '../assets/photos/v184_1531.jpg'
import image12 from '../assets/photos/v184_1532.jpg'
import image13 from '../assets/photos/v184_1540.jpg'
import image14 from '../assets/photos/v184_1541.jpg'
import image15 from '../assets/photos/v184_1542.jpg'
import image16 from '../assets/photos/v184_1543.jpg'
import image17 from '../assets/photos/v184_1544.jpg'
import image18 from '../assets/photos/v184_1545.jpg'
import image19 from '../assets/photos/v184_1546.jpg'
import image20 from '../assets/photos/v184_1547.jpg'
import image21 from '../assets/photos/v184_1548.jpg'
import image22 from '../assets/photos/v184_1549.jpg'
import image23 from '../assets/photos/v184_1550.jpg'
import image24 from '../assets/photos/v184_1551.jpg'
import image25 from '../assets/photos/v184_1552.jpg'
import image26 from '../assets/photos/v184_1553.jpg'
import image27 from '../assets/photos/v184_1554.jpg'


const photos = [
  { src: image1, width: 480, height: 270, link: 'https://link1.com' },
  { src: image2, width: 480, height: 270, link: 'https://link2.com' },
  { src: image3, width: 480, height: 270, link: 'https://link3.com' },
  { src: image11, width: 480, height: 270, link: 'https://link4.com' },
  { src: image4, width: 480, height: 270, link: 'https://link5.com' },
  { src: image12, width: 480, height: 270, link: 'https://link6.com' },
  { src: image5, width: 480, height: 270, link: 'https://link7.com' },
  { src: image6, width: 480, height: 270, link: 'https://link8.com' },
  { src: image7, width: 480, height: 270, link: 'https://link9.com' },
  { src: image8, width: 480, height: 270, link: 'https://link10.com' },
  { src: image9, width: 480, height: 270, link: 'https://link11.com' },
  { src: image10, width: 480, height: 270, link: 'https://link12.com' },
  { src: image13, width: 480, height: 270, link: 'https://link13.com' },
  { src: image14, width: 480, height: 270, link: 'https://link14.com' },
  { src: image15, width: 480, height: 270, link: 'https://link15.com' },
  { src: image16, width: 480, height: 270, link: 'https://link16.com' },
  { src: image17, width: 480, height: 270, link: 'https://link17.com' },
  { src: image18, width: 480, height: 270, link: 'https://link18.com' },
  { src: image19, width: 480, height: 270, link: 'https://link19.com' },
  { src: image20, width: 480, height: 270, link: 'https://link20.com' },
  { src: image21, width: 480, height: 270, link: 'https://link21.com' },
  { src: image22, width: 480, height: 270, link: 'https://link22.com' },
  { src: image23, width: 480, height: 270, link: 'https://link23.com' },
  { src: image24, width: 480, height: 270, link: 'https://link24.com' },
  { src: image25, width: 480, height: 270, link: 'https://link25.com' },
  { src: image26, width: 480, height: 270, link: 'https://link26.com' },
  { src: image27, width: 480, height: 270, link: 'https://link27.com' },
];

//const duplicatePhotos = [...photos, ...photos]; // Duplicate the photos for seamless looping

const PhotoGallery: React.FC = () => {
  const renderPhotos = (startIndex: number) => {
    const loopedPhotos = [...photos, ...photos]; // Duplicate the photos for seamless looping
    const selectedPhotos = loopedPhotos.slice(startIndex, startIndex + photos.length);
    return selectedPhotos.map((photo, index) => (
      <div key={index} className="animated-photo">
          <img src={photo.src} alt={`Slide ${index}`} className='photo-img'/>
      </div>
    ));
  };

  return (
    <div className="photo-gallery">
      <div className="photo-gallery-inner slide-left">
        {renderPhotos(0)} {/* Row 1 starts with image1 */}
      </div>
      <div className="photo-gallery-inner slide-right">
        {renderPhotos(9)} {/* Row 2 starts with image5 */}
      </div>
      <div className="photo-gallery-inner slide-left">
        {renderPhotos(18)} {/* Row 3 starts with image9 */}
      </div>
    </div>
    
  );
};

export default PhotoGallery;
