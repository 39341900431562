//import React from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOffers } from '../../hooks/useOffers'
import { Zoom } from 'react-awesome-reveal'
import PhotoGallery from '../../components/PhotoGallery'
import FAQs from '../../components/TryFAQ'
import 'bootstrap/dist/css/bootstrap.min.css'
import heroImage from '../../assets/hero-v1.png'
import nyt from '../../assets/nyt.svg'
import ellen from '../../assets/ellen.svg'
import forbes from '../../assets/forbes.svg'
import jimmy from '../../assets/jimmy.svg'
import usaToday from '../../assets/usaToday.svg'
import tmz from '../../assets/tmz.svg'
import twp from '../../assets/twp.svg'
import guardian from '../../assets/guardian.svg'
import iosLogo from '../../assets/ios.svg'
import chromeLogo from '../../assets/chrome.svg'
import firetvLogo from '../../assets/firetv.svg'
import rokuLogo from '../../assets/roku.svg'
import androidLogo from '../../assets/android.svg'
import samsungLogo from '../../assets/samsung.svg'
import appletvLogo from '../../assets/appletv.svg'
import checkIcon from '../../assets/check.svg'
import faqBG from '../../assets/faqBG.svg'
import faqdoggy from '../../assets/faq-doggy.jpg'
import fbstars from '../../assets/fbstars.png'
import blueCheck from '../../assets/blueCheck.svg'
import orangeCheck from '../../assets/orangeCheck.svg'
import planBackground from '../../assets/planBackgroundWhite.svg'
import clapperv2 from '../../assets/clapperv2.svg'
import couchv2 from '../../assets/couchv3.svg'
import ribbonv2 from '../../assets/ribbonv3.svg'
import herov1 from '../../assets/Cover.jpg'
import backdrop8th from '../../assets/petsmart/backdrop8thPetsmartv2.jpg'
import onTV from '../../assets/petsmart/backdrop8thPetsmart.jpg'

export const TryHome: React.FC = () => {
  const { offers, offersLoading, setIsPickOfferFirstFlow, setSelectedOffer } = useOffers();
  const navigate = useNavigate()
  const offset = window.innerWidth <= 768 ? 50 : 80
  const [isVisible, setIsVisible] = useState(false)
  const shapeDividerRef = useRef<HTMLDivElement | null>(null)
  const [isStickyVisible, setIsStickyVisible] = useState(true)

  useEffect(() => {
    document.title = "DOGTV | The Ultimate Streaming Platform for Dogs & Their Humans | Try";
  }, []);

  const onSelectOffer = () => {
    navigate('/try/checkout')
  }


  const handleClick = (path: string) => {
    navigate(path)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          if (shapeDividerRef.current) {
            observer.unobserve(shapeDividerRef.current)
          }
        }
      },
      { threshold: 0.1 },
    )

    if (shapeDividerRef.current) {
      observer.observe(shapeDividerRef.current)
    }

    return () => {
      if (shapeDividerRef.current) {
        observer.unobserve(shapeDividerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const anchors = document.querySelectorAll('a[href^="#"]')

    const handleAnchorClick = (e: Event) => {
      e.preventDefault()

      const targetId = (e.currentTarget as HTMLAnchorElement)
        .getAttribute('href')!
        .substring(1)
      const targetElement = document.getElementById(targetId)

      if (targetElement) {
        const elementPosition = targetElement.offsetTop
        const offsetPosition = elementPosition - offset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }

    anchors.forEach((anchor) =>
      anchor.addEventListener('click', handleAnchorClick as EventListener),
    )

    return () => {
      anchors.forEach((anchor) =>
        anchor.removeEventListener('click', handleAnchorClick as EventListener),
      )
    }
  }, [offset])

  useEffect(() => {
    const handleScroll = () => {
      const startNowSection = document.getElementById('start-now')
      const rect = startNowSection?.getBoundingClientRect()
      const isInView = rect
        ? rect.top <= window.innerHeight && rect.bottom >= 0
        : false

      setIsStickyVisible(!isInView)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      {/* hero section - 1st*/}
      <div className='container-fluid section-with-diagonal hero-background' style={{ background: 'var(--Black)' , backgroundImage: `url(${herov1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', zIndex:'1000' }}>
      {/* <PhotoGallery />*/}
      <div className="overlay"></div>
      <div className='row px-mobile pt-5 pb-3'>
        <div className="col-md-6 d-flex align-items-center text-light pt-3 pt-md-5">
          <div className="d-flex flex-column">
            <h2 className='text-center text-md-start pb-md-0 pb-4'>The Ultimate Streaming Platform for Dogs & Their Humans</h2>
            <ul className='checklist'>
              <li style={{ backgroundImage: `url(${checkIcon})` }}>Developed for Dogs & Humans</li>
              <li style={{ backgroundImage: `url(${checkIcon})` }}>Unlimited Access</li>
              <li style={{ backgroundImage: `url(${checkIcon})` }}>Always Ad Free</li>
              <li style={{ backgroundImage: `url(${checkIcon})` }}>Stream Anytime, Anywhere</li>
              <li style={{ backgroundImage: `url(${checkIcon})` }}>No Contract, Cancel Anytime</li>
              <li style={{ backgroundImage: `url(${checkIcon})` }}>Trusted by Dog Owners Worldwide</li>
            </ul>
            <button className="btn btn-primary d-flex mt-auto mx-auto-mobile"
              onClick={(e) => {
                e.stopPropagation()
                onSelectOffer()
              }}
            >Start 1 Month Free Trial</button>
            <p className='py-2 text-md-start text-center pl1rem'>30-day money back guarantee</p>
          </div>
        </div>
        <div className="col-md-6 text-center d-flex align-items-center justify-content-center pt-3 pt-md-5">
          <Zoom triggerOnce>
            <img loading="lazy" decoding="async" width="1024" height="996" src={heroImage} className="img-fluid animated-image floating w-75" alt="landing-hero-v1" />
          </Zoom>
        </div>
      </div>
      <div className={`custom-shape-divider-bottom shape-divider-animation ${isVisible ? 'animate-shape' : ''}`} ref={shapeDividerRef}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 0V61H0L1200 0z" className="shape-fill"></path>
        </svg>
      </div>
    </div>
    {/* end of hero section - 1st */}
    {/* as seen on section - 2nd */}
    <div className='container-fluid' style={{ background: 'var(--White)' }}>
    <div className="row text-center pt-5 logos-section px-mobile">
        <div className="col-12">
          <h5 className='py-3' style={{color:'var(--Seadog-Blue)',}}>As Seen On</h5>
        </div>
        <div className="col-md-2 col-3 my-3"> 
          <Zoom triggerOnce>
          <img loading="lazy" decoding="async" width="180" height="125" src={nyt} className="img-fluid zoom-img" alt="nyt" />
          </Zoom>
        </div>
        <div className="col-md-2 col-3 my-3"> 
          <Zoom triggerOnce>
          <img loading="lazy" decoding="async" width="180" height="125" src={ellen} className="img-fluid zoom-img" alt="ellen" />
          </Zoom>
        </div>
        <div className="col-md-2 col-3 my-3"> 
          <Zoom triggerOnce>
          <img loading="lazy" decoding="async" width="180" height="125" src={forbes} className="img-fluid zoom-img" alt="forbes" />
          </Zoom>
        </div>
        <div className="col-md-1 col-3 my-3 hide"> 
          <Zoom triggerOnce>
          <img loading="lazy" decoding="async" width="180" height="125" src={jimmy} className="img-fluid zoom-img" alt="jimmy" />
          </Zoom>
        </div>
        <div className="col-md-2 col-3 my-3"> 
          <Zoom triggerOnce>
          <img loading="lazy" decoding="async" width="180" height="125" src={usaToday} className="img-fluid zoom-img" alt="usaToday" />
          </Zoom>
        </div>
        <div className="col-md-2 col-3 my-3"> 
          <Zoom triggerOnce>
          <img loading="lazy" decoding="async" width="180" height="125" src={twp} className="img-fluid zoom-img" alt="twp" />
          </Zoom>
        </div>
        <div className="col-md-2 col-3 my-3"> 
          <Zoom triggerOnce>
          <img loading="lazy" decoding="async" width="180" height="125" src={guardian} className="img-fluid zoom-img" alt="guardian" />
          </Zoom>
        </div>
        <div className="col-md-2 col-3 my-3"> 
          <Zoom triggerOnce>
          <img loading="lazy" decoding="async" width="180" height="125" src={tmz} className="img-fluid zoom-img" alt="tmz" />
          </Zoom>
        </div>
      </div>
      <div className={`custom-shape-divider-bottom shape-divider-animation ${isVisible ? 'animate-shape' : ''}`} ref={shapeDividerRef}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M1200 0V61H0L1200 0z" className="shape-fill2"></path>
      </svg>
    </div>
      </div>
      {/* end of as seen on section - 2nd */}
      {/* what's inside section - 3rd */}
      <div className='container-fluid whats-inside-section' style={{ background: 'var(--Retriever-Gold)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="row px-mobile">
          <div className="col-12 text-center mb-4">
            <h5 className='pt-5 pb-3' style={{color:'var(--White)',}}>WHAT'S INSIDE?</h5>
            <h2 className='text-black'>Features You'll Love</h2>
          </div>
          <div className="col-12 text-center">
            <div className="row text-black">
              <div className="col-md-4 col-10 my-3 mx-auto">
                <Zoom triggerOnce>
                  <img loading="lazy" decoding="async" src={couchv2} className="img-fluid pb-4 zoom-img" alt="couchv2" />
                </Zoom>
                <h4 className='pb-3 px-md-3'>Unlimited Shows, Music, 
                and Dogumentaries</h4>
                <p className='px-md-3'>Discover an endless selection of shows, music, and documentaries tailored to you and your dog's preferences and needs.</p>
              </div>
              <div className="col-md-4 col-10 my-3 mx-auto">
                <Zoom triggerOnce>
                  <img loading="lazy" decoding="async" src={clapperv2} className="img-fluid pb-4 zoom-img" alt="clapperv2" />
                </Zoom>
                <h4 className='pb-3 px-md-3'>Expert-Designed and Approved Content</h4>
                <p className='px-md-3'>Our content is designed and developed by seasoned directors, pet experts and renowned veterinarians to stimulate both you and your dog, providing the ultimate enrichment experience.</p>
              </div>
              <div className="col-md-4 col-10 my-3 mx-auto">
                <Zoom triggerOnce>
                  <img loading="lazy" decoding="async" src={ribbonv2} className="img-fluid pb-4 zoom-img" alt="ribbonv2" />
                </Zoom>
                <h4 className='pb-3 px-md-3'>Completely 
                Ad-Free Viewing</h4>
                <p className='px-md-3'>Enjoy DOGTV on all your devices, with unlimited 24/7 streaming that is always ad-free.</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`custom-shape-divider-bottom shape-divider-animation ${isVisible ? 'animate-shape' : ''}`} ref={shapeDividerRef}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0V61H0L1200 0z" className="shape-fill3"></path>
          </svg>
        </div>
      </div> 
    {/* end of what's inside section - 3rd */}
    {/* What's Playing section - 4th */} 
    <div className='container-fluid section-with-diagonal pb-4' style={{ background: 'var(--Black)', }}>
      <div className="row d-flex align-items-end pt-5 pb-4 px-mobile"> 
        <div className="col-md-9 text-md-start text-center align-items-end text-light pb-3 pb-md-0">
        <h2>What's Playing?</h2>
          <p className='py-2 fs-6'>Binge-Worthy Award-Winning Content, Anytime, Anywhere. <strong>Because Your Dog Deserves the Best.</strong></p>
        </div>
        <div className="col-md-3 text-md-end d-flex align-items-center align-items-md-end text-light">
        <button
          className="btn btn-primary d-flex mt-auto mx-auto-mobile"
          onClick={(e) => {
            e.stopPropagation()
            onSelectOffer()
          }}
        >Start 1 Month Free Trial</button>
        </div>
      </div>
      <div className='row stretched text-light'>
        <div className="col-12 mb-4">
            <PhotoGallery />
        </div>
      </div>
      <div className={`hide custom-shape-divider-bottom shape-divider-animation ${isVisible ? 'animate-shape' : ''}`} ref={shapeDividerRef}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 0V61H0L1200 0z" className="shape-fill"></path>
        </svg>
      </div>
    </div>
    {/* end of What's Playing section - 4th */}
    {/* orange CTA section - 5th */}
    <div className='container-fluid section-with-diagonal ' style={{ background: '#F57F20' }}>
      <div className="row d-flex align-items-end pt-5 pb-1 px-mobile"> 
        <div className="col-md-9 text-md-start text-center align-items-end text-light">
        <h3 className='w-md-75 pb-3 pb-md-0 '>Pawsome shows you and your dog will love.
        Treat yourself to the best in doggy entertainment.</h3>
        </div>
        <div className="col-md-3 text-center  align-items-center align-items-md-end text-light">
        <button className="btn btn-black d-flex mt-auto mx-auto-mobile" onClick={(e) => {
          e.stopPropagation()
          onSelectOffer()
        }}
      >Start 1 Month Free Trial</button>
          <p className='py-2 fs-6 text-center'>30-day money back guarantee </p>
        </div>
      </div>
        <div className={`custom-shape-divider-bottom shape-divider-animation ${isVisible ? 'animate-shape' : ''}`} ref={shapeDividerRef}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0V61H0L1200 0z" className="shape-fill"></path>
          </svg>
        </div>
      </div>
      {/* end of orange CTA section - 5th */}
      {/* app logos section - 6th */}
        <div className='container-fluid ' style={{ background: '#ffffff' }}>
          <div className="row text-center pt-5 logos-section px-mobile">
            <div className="col-12">
              <h5 className='py-3' style={{color:'var(--Seadog-Blue)',}}>Available anywhere, anytime.</h5>
              <h2 className='pb-3 pb-md-5  mx-auto'>Unlimited premium content for dog lovers and their 4-legged best friends.</h2>
              <button className="btn btn-primary d-flex align-items-center justify-content-center mx-auto" onClick={(e) => {
                  e.stopPropagation()
                  onSelectOffer()
                }}
              >Start 1 Month Free Trial</button>                      
            </div>
            <div className="col-md-2 col-3 my-3"> 
              <Zoom triggerOnce>
              <img loading="lazy" decoding="async" width="120" height="125" src={iosLogo} className="img-fluid zoom-img" alt="ios" />
              </Zoom>
            </div>
            <div className="col-md-2 col-3 my-3"> 
              <Zoom triggerOnce>
              <img loading="lazy" decoding="async" width="120" height="125" src={chromeLogo} className="img-fluid zoom-img" alt="chrome" />
              </Zoom>
            </div>
            <div className="col-md-2 col-3 my-3"> 
              <Zoom triggerOnce>
              <img loading="lazy" decoding="async" width="120" height="125" src={firetvLogo} className="img-fluid zoom-img" alt="firetv" />
              </Zoom>
            </div>
            <div className="col-md-2 col-3 my-3"> 
              <Zoom triggerOnce>
              <img loading="lazy" decoding="async" width="120" height="125" src={rokuLogo} className="img-fluid zoom-img" alt="roku" />
              </Zoom>
            </div>
            <div className="col-md-2 col-3 my-3"> 
              <Zoom triggerOnce>
              <img loading="lazy" decoding="async" width="120" height="125" src={androidLogo} className="img-fluid zoom-img" alt="android" />
              </Zoom>
            </div>
            <div className="col-md-2 col-3 my-3"> 
              <Zoom triggerOnce>
              <img loading="lazy" decoding="async" width="120" height="125" src={samsungLogo} className="img-fluid zoom-img" alt="samsung-smart-tv" />
              </Zoom>
            </div>
            <div className="col-md-2 col-3 my-3"> 
              <Zoom triggerOnce>
              <img loading="lazy" decoding="async" width="120" height="125" src={appletvLogo} className="img-fluid zoom-img" alt="apple-tv" />
              </Zoom>
            </div>
          </div>
        </div>
      {/* end of app logos section - 6th */}
      {/* TV section - 7th */}
      <div className='container-fluid withbgimage' style={{ backgroundImage: `url(${backdrop8th})`, backgroundColor: 'var(--White)' }}>
        <div className="row pb-5">
          <div className="col-md-9 col-12 text-center mx-auto">
            <img loading="lazy" decoding="async" width="2560" height="1457" src={onTV} className="img-fluid" alt="onTV" />
          </div>
        </div>
      </div>
      {/* end of TV section - 7th */}
      {/* Pricing section - 8th  {offer.offerPrice} 
      <div id='start-now' className='container-fluid withbgimage nobgimagemobile nostickybtn' style={{ backgroundImage: `url(${planBackground})`, backgroundColor:'var(--Bone)'}}>
        <div className="row my-4 plan-section px-mobile">
          <div className="text-center mb-4">
            <h5 className='py-1' style={{ color: 'var(--Seadog-Blue)' }}>PRICING</h5>
            <h1 className='section-title'>Select your plan</h1>
            <hr className="spacer" />
          </div>
          <div className="row px-md-1 px-0 justify-content-center">
            {offers.map((offer, index) => (
              <div className="col-md-4 mb-4 d-flex align-items-center" key={offer.offerId}>
                <div id={offer.offerId} className={`card text-left select-offer offer-${index}`} onClick={() => onSelectOffer(offer)} style={{ cursor: 'pointer' }} >
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title pb-3" style={{ color: 'var(--Seadog-Blue)' }}>{offer.offerTitle}</h5>
                    <h6 className="card-text price-text">{offer.offerCurrencySymbol} {getPriceLabel(index)}</h6>
                    <p className='subtext' style={{color:'var(--Heckin-Red)', fontWeight:'700' }}>{getSubtextLabel(index)}</p>
                    <p>Includes:</p>
                    <ul className='planlist'>
                      <li style={{ backgroundImage: `url(${orangeCheck})` }}>1 Month Free of DOGTV</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Unlimited Access</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Always Ad Free</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Stream Anytime, Anywhere</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>No Contract, Cancel Anytime</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Global Dog Loving Community</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Tips from Veterinarians & Pet Experts</li>
                      <li style={{ backgroundImage: `url(${blueCheck})` }}>Trusted by Dog Owners Worldwide</li>
                    </ul>
                    <button className="btn btn-primary d-flex mt-auto" onClick={(e) => {
                        e.stopPropagation()
                        onSelectOffer()
                      }}>
                      {getButtonLabel(index)}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* end of Pricing section - 8th */}
      {/* FAQs section - 9th */}
      <div className='container-fluid section-with-diagonal nobgimagemobile' style={{ background: 'var(--Seadog-Blue)', backgroundImage: `url(${faqBG})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className='row pt-3 text-light text-center px-mobile'>
          <div className="col-12 pt-5 mb-4">
            <h5 className='py-3' style={{ color: 'var(--Retriever-Gold)' }}>QUESTIONS?</h5>
            <h2>Frequently Asked Questions</h2>
          </div>
        </div>
        <div className='row text-light px-mobile'>
          <div className="col-md-8 col-12 mb-4 mx-auto">
            <FAQs />
          </div>
          <div className="col-md-12 col-8 mx-auto text-center ">
            <Zoom triggerOnce>
            <img loading="lazy" decoding="async" width="360" height="391" src={fbstars} className="img-fluid zoom-img" alt="fbstars" />
            </Zoom>
          </div>
          <div className="col-12 text-center">
            <img loading="lazy" decoding="async" width="488" height="391" src={faqdoggy} className="img-fluid" alt="faqdoggy" />
          </div>
        </div>
      </div>
      {/* end of FAQs section - 9th */}
    </>
      
  )
}
