import React, { useEffect, useRef } from 'react';
import { useRegister } from '../hooks/useRegister';
import { useLocation } from 'react-router-dom';

interface IProps {
  couponComponent?: JSX.Element
}

export const Register: React.FC<IProps> = ({ couponComponent = null }) => {
  const { consents, errors, loading, handleChange, handleSubmit } = useRegister();
  const emailRef = useRef<HTMLInputElement>(null);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page loads at the top
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, [location.pathname]); 
  
  return (
    <>
      <h3 className='hide'>Register</h3>
      <form id="register" onChange={handleChange} onSubmit={handleSubmit}>
        <label htmlFor="email">Email Address:</label>
        <br />
        <input type="email" id="email" name="email" placeholder="Email Address" required ref={emailRef} />
        <div className="password-fields">
          <div className="field">
            <label htmlFor="password">Password:</label><br />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              pattern="^(?=.*[A-Za-z])(?=.*[\d!@#$%^&])[A-Za-z\d!@#$%^&]{8,}$"
              title="8-characters minimum. At least one letter and one number or special character (!@#$%^&)."
              required
            />
          </div>
          <div className="field">
            <label htmlFor="confirm">Repeat Password:</label><br />
            <input
              type="password"
              id="confirm"
              name="confirm"
              placeholder="Repeat Password"
              pattern="^(?=.*[A-Za-z])(?=.*[\d!@#$%^&])[A-Za-z\d!@#$%^&]{8,}$"
              required
            />
          </div>
        </div>
        <div className="name-fields">
          <div className="field">
            <label htmlFor="fname">First Name:</label>
            <br />
            <input type="text" id="fname" name="fname" placeholder="First Name" required />
          </div>
          <div className="field">
            <label htmlFor="lname">Last Name:</label>
            <br />
            <input type="text" id="lname" name="lname" placeholder="Last Name" required />
          </div>
        </div>
        {consents.map((consent, i) => (
          <div key={i} className="consent-field">
            <input
              type="checkbox"
              id={consent.name}
              name={consent.name}
              defaultChecked={consent.enabledByDefault}
              required={consent.required}
            />
            <label
              htmlFor={consent.name}
              dangerouslySetInnerHTML={{ __html: consent.label }}
            />
          </div>
        ))}
        <br />
        {couponComponent}
        <input type="submit" value="Next" disabled={loading} />
        {errors.map((error, i) => (
          <div key={i} className="error">
            {error}
          </div>
        ))}
      </form>
      <p style={{textAlign:'center', margin:'20px auto 10px'}}>Already have an account? <a href='https://watch.dogtv.com/' target='_blank' style={{color:'var(--Orange)'}}>Sign in</a>.</p>
    </>
  )
}