import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Zoom } from 'react-awesome-reveal';
import PhotoGallery from '../components/PhotoGallery';

import tyHero from '../assets/tyHero.svg';
import iosLogo from '../assets/ios.svg';
import chromeLogo from '../assets/chrome.svg';
import firetvLogo from '../assets/firetv.svg';
import rokuLogo from '../assets/roku.svg';
import androidLogo from '../assets/android.svg';
import samsungLogo from '../assets/samsung.svg';
import appletvLogo from '../assets/appletv.svg';
import faqdoggy from '../assets/faq-doggy.jpg';
import pawsOn from '../assets/paws_on.svg';
import referDoggies from '../assets/referDoggies.png';
import DoggieBoard from '../assets/DoggieBoard.png';
import FBlogo from '../assets/FBlogo.png';
import Tiktoklogo from '../assets/Tiktoklogo.png';
import IGlogo from '../assets/IGlogo.png';
import Xlogo from '../assets/Xlogo.png';
import YTlogo from '../assets/YTlogo.png';

export const Success: React.FC = () => {
  const shapeDividerRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (shapeDividerRef.current) {
            observer.unobserve(shapeDividerRef.current);
          }
        }
      },
      { threshold: 0.1 }
    );

    if (shapeDividerRef.current) {
      observer.observe(shapeDividerRef.current);
    }

    return () => {
      if (shapeDividerRef.current) {
        observer.unobserve(shapeDividerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      gtag('event', 'conversion_event_signup', {
        // <event_parameters>
      });
    `;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* 5th section */}
      <div className='hero container-fluid' style={{ background: '#FFC70A', backgroundImage: `url(${tyHero})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="row px-mobile py-4">
          <div className="col-12 text-center py-5">
            <h1 className='section-title' style={{ color: 'var(--Seadog-Blue)', }}>Thank you furry much for subscribing!</h1>
            <h6><strong>Welcome to the DOGTV pack!</strong></h6>
            <a href="https://watch.dogtv.com/" className="btn btn-hero d-flex align-items-center justify-content-center mt-3 mx-auto">Sign-in</a>
          </div>
        </div>
        <div className={`custom-shape-divider-top shape-divider-animation ${isVisible ? 'animate-shape' : ''}`} ref={shapeDividerRef}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0V61H0L1200 0z" className="shape-fill"></path>
          </svg>
        </div>
      </div>
      {/* end of 5th section */}
      {/* 7th section */}
      <div className='container-fluid py-4' style={{ background: 'var(--White)',}}>
        <div className="row text-center py-5 logos-section px-mobile">
          <div className="col-12">
            <h5 className='py-3 hide' style={{ color: 'var(--Seadog-Blue)', }}>Available anywhere, anytime.</h5>
            <h3 className='pb-3 pb-md-5 mx-auto'>Use the login and password you created to access DOGTV on your favorite streaming device:</h3>
          </div>
          <div className="col-md-2 col-3 my-3">
            <img loading="lazy" decoding="async" width="120" height="125" src={iosLogo} className="img-fluid" alt="ios" />
          </div>
          <div className="col-md-2 col-3 my-3">
            <img loading="lazy" decoding="async" width="120" height="125" src={chromeLogo} className="img-fluid" alt="chrome" />
          </div>
          <div className="col-md-2 col-3 my-3">
            <img loading="lazy" decoding="async" width="120" height="125" src={firetvLogo} className="img-fluid" alt="firetv" />
          </div>
          <div className="col-md-2 col-3 my-3">
            <img loading="lazy" decoding="async" width="120" height="125" src={rokuLogo} className="img-fluid" alt="roku" />
          </div>
          <div className="col-md-2 col-3 my-3">
            <img loading="lazy" decoding="async" width="120" height="125" src={androidLogo} className="img-fluid" alt="android" />
          </div>
          <div className="col-md-2 col-3 my-3">
            <img loading="lazy" decoding="async" width="120" height="125" src={samsungLogo} className="img-fluid" alt="samsung-smart-tv" />
          </div>
          <div className="col-md-2 col-3 my-3">
            <img loading="lazy" decoding="async" width="120" height="125" src={appletvLogo} className="img-fluid" alt="apple-tv" />
          </div>
        </div>
      </div>
      {/* end of 7th section */}
      {/* 4th section */}
      <div className='container-fluid section-with-diagonal' style={{ background: 'var(--Seadog-Blue)', }}>
        <div className='row'>
          <div className="col-md-6 text-center order-2 order-md-1 d-flex align-items-center justify-content-center">
            <img loading="lazy" decoding="async" width="1024" height="996" src={faqdoggy} className="img-fluid animated-image w-75" alt="landing-hero-v1" />
          </div>
          <div className="col-md-6 text-center order-1 order-md-2 d-flex align-items-center justify-content-center text-light">
            <div className=" pt-md-0 py-5">
              <h2 className='text-md-start text-center'>Need Help?</h2>
              <hr className="h-40" />
              <a href="https://help.dogtv.com/hc/en-us" target="_blank" rel="noopener noreferrer" className="btn btn-yellow d-flex text-center mx-auto">Search Helpdesk Articles</a>
            </div>
          </div>
        </div>
      </div>
      {/* end of 4th section */}
      {/* 4th section */}
      <div className='container-fluid section-with-diagonal' style={{ background: 'var(--White)' }}>
        <div className='row py-5'>
          <div className="col-md-6 text-center d-flex align-items-center justify-content-center text-black">
            <div className="pt-md-0 pt-5">
              <h2 className='text-center'>Get featured<br />on DOGTV!</h2>
              {/* Social media links */}
              <div className="d-flex justify-content-center my-4">
                <a href="https://www.facebook.com/dogtv" target="_blank" rel="noopener noreferrer" className="mx-2 text-light">
                  <img loading="lazy" decoding="async" width="64" height="64" src={FBlogo} className="img-fluid animated-image w-75" alt="Facebook Logo" />
                </a>
                <a href="https://www.instagram.com/DOGTV/" target="_blank" rel="noopener noreferrer" className="mx-2 text-light">
                  <img loading="lazy" decoding="async" width="64" height="64" src={IGlogo} className="img-fluid animated-image w-75" alt="Instagram Logo" />
                </a>
                <a href="https://www.tiktok.com/@dogtvofficial" target="_blank" rel="noopener noreferrer" className="mx-2 text-light">
                  <img loading="lazy" decoding="async" width="64" height="64" src={Tiktoklogo} className="img-fluid animated-image w-75" alt="TikTok Logo" />
                </a>
                <a href="https://www.twitter.com/dogtv" target="_blank" rel="noopener noreferrer" className="mx-2 text-light">
                  <img loading="lazy" decoding="async" width="64" height="64" src={Xlogo} className="img-fluid animated-image w-75" alt="Twitter Logo" />
                </a>
                <a href="https://www.youtube.com/dogtvworld" target="_blank" rel="noopener noreferrer" className="mx-2 text-light">
                  <img loading="lazy" decoding="async" width="64" height="64" src={YTlogo} className="img-fluid animated-image w-75" alt="LinkedIn Logo" />
                </a>
              </div>
              <hr className="h-40" />
              <a href="https://www.dogtv.com/getsocial" target="_blank" rel="noopener noreferrer" className="btn btn-primary d-flex align-items-center justify-content-center mx-auto">Submit Photo/Video</a>
              <h6 className='text-black w-75 mx-auto pt-3'>Share your photos, videos and testimonials! They could be featured on our channel and across social platforms.</h6>
            </div>
          </div>
          <div className="col-md-6 text-center d-flex align-items-center justify-content-center">
            <Zoom>
              <img loading="lazy" decoding="async" width="1024" height="996" src={DoggieBoard} className="img-fluid floating animated-image w-75" alt="Doggie Board" />
            </Zoom>
          </div>
        </div>
      </div>
      {/* end of 4th section */}
      {/* 5th section 
      <div className='container-fluid' style={{ background: 'var(--Painting-Pink)', backgroundImage: `url(${pawsOn})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className='row pt-5 pb-4'>
          <div className="col-md-6 text-center d-flex ">
            <Zoom>
              <img loading="lazy" decoding="async" width="1024" height="996" src={referDoggies} className="img-fluid floating animated-image w-75" alt="landing-hero-v1" />
            </Zoom>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center text-light">
            <div className="text-center align-items-center justify-content-center pt-md-0 pt-5">
              <h5 className='py-3 hide' style={{ color: 'var(--Black)', }}>RUFFERRING DOGTV</h5>
              <h2 className='text-center'>Leave us a RUFFeral</h2>
              <h6 className='text-black w-75 mx-auto'>Did you know you can earn gift cards to your favorite stores just by RUFFering DOGTV to friends & family?</h6>
              <hr className="h-40" />
              <a href="https://referral-factory.com/RIJQUP/" target="_blank" rel="noopener noreferrer" className="btn btn-black d-flex mx-auto">Join Now</a>
            </div>
          </div>
        </div>
        <div className={`custom-shape-divider-top shape-divider-animation ${isVisible ? 'animate-shape' : ''}`} ref={shapeDividerRef}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0V61H0L1200 0z" className="shape-fill3"></path>
          </svg>
        </div>
      </div>
      {/* end of 5th section */}
      {/* 6th section */}
      <div className='container-fluid section-with-diagonal' style={{ background: 'var(--Black)', }}>
        <div className="row d-flex align-items-center pt-5 pb-4 px-mobile">
          <div className="col-12 text-center">
          <a href="https://watch.dogtv.com/" className="btn btn-primary d-flex align-items-center justify-content-center mt-3 mx-auto">Sign-in</a>
          </div>
        </div>

        <div className='row stretched text-light'>
          <div className="col-12 mb-4">
            <PhotoGallery />
          </div>
        </div>
      </div>
      {/* end of 6th section */}
    </>
  );
};
