import { isProduction } from '../utils/env'

const API_URL = isProduction()
  ? 'https://mediastoreapi.cleeng.com'
  : 'https://mediastoreapi-sandbox.cleeng.com'

const PUBLISHER_ID = isProduction() ? '686320448' : '320422419'

export const useCleeng = () => {
  const fetchFromApi = async (endpoint: string, init?: RequestInit) => {
    const result = await fetch(`${API_URL}/${endpoint}`, init)

    return result.json()
  }

  const fetchConsents = async (): Promise<
    IApiResponse<{ consents: IConsent[] }>
  > => {
    return fetchFromApi(`publishers/${PUBLISHER_ID}/consents`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    })
  }

  const fetchLocale = async (): Promise<IApiResponse<ILocale>> => {
    return fetchFromApi('locales', {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    })
  }

  const registerCustomer = async (
    data: IRegistration,
  ): Promise<IApiResponse<ICustomer>> => {
    return fetchFromApi('customers', {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
        publisherId: PUBLISHER_ID,
        locale: data.locale,
        country: data.country,
        currency: data.currency,
        firstName: data.firstName,
        lastName: data.lastName,
      }),
    })
  }

  const updateCustomerConsents = async (
    customerId: number,
    consents: ICustomerConsent[],
    token: string,
  ): Promise<IApiResponse<{}>> => {
    return fetchFromApi(`customers/${customerId}/consents`, {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        consents,
      }),
    })
  }

  const fetchOfferDetails = (
    offerId: string,
  ): Promise<IApiResponse<IOffer>> => {
    return fetchFromApi(`offers/${offerId}`)
  }

  const createOrder = (
    offerId: string,
    customerId: number,
    locale: ILocale,
    token: string,
    couponCode?: string,
  ): Promise<
    IApiResponse<{ message: string; order: IOrder; success: boolean }>
  > => {
    return fetchFromApi(`orders`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        offerId,
        customerId,
        country: locale.country,
        currency: locale.currency,
        ...(couponCode ? { couponCode } : {}),
      }),
    })
  }

  const fetchPaymentMethods = (
    token: string,
  ): Promise<
    IApiResponse<{
      paymentMethods: IPaymentMethod[]
      message: string
      status: number
    }>
  > => {
    return fetchFromApi(`payment-methods`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
  }

  const updateOrder = (
    orderId: number,
    paymentMethodId: number,
    token: string,
  ): Promise<
    IApiResponse<{ message: string; order: IOrder; success: boolean }>
  > => {
    return fetchFromApi(`orders/${orderId}`, {
      method: 'PATCH',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({ paymentMethodId }),
    })
  }

  const createAdyenPaymentSession = (
    token: string,
  ): Promise<IApiResponse<IAdyenPaymentSession>> => {
    return fetchFromApi(`connectors/adyen/sessions`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        returnUrl: `${window.location.href}success`,
        filterPaymentMethods: ['card'],
        //filterPaymentMethods: ['card', 'applepay', 'googlepay'],
      }),
    })
  }

  const createAdyenPayment = (
    orderId: number,
    onSubmitResult: any,
    token: string,
  ) => {
    return fetchFromApi(`connectors/adyen/initial-payment`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        orderId,
        returnUrl: `${window.location.href}success`,
        paymentMethod: onSubmitResult.data.paymentMethod,
      }),
    })
  }

  const createPaypalPayment = (
    orderId: number,
    token: string,
  ): Promise<IApiResponse<{ redirectUrl: string }>> => {
    return fetchFromApi(`connectors/paypal/v1/tokens`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        orderId,
        successUrl: `${window.location.href}success`,
        cancelUrl: window.location.href,
        errorUrl: window.location.href,
      }),
    })
  }

  return {
    fetchConsents,
    fetchLocale,
    registerCustomer,
    updateCustomerConsents,
    fetchOfferDetails,
    createOrder,
    fetchPaymentMethods,
    updateOrder,
    createAdyenPaymentSession,
    createAdyenPayment,
    createPaypalPayment,
  }
}
