import React from 'react';
import Accordion from './Accordion';

const Term: React.FC = () => {
  const termItems = [
    {
      title: '1. Consent and Modification.',
      content: "By using or visiting this Site, you signify your assent to these Terms. These Terms apply to all users of the Site. If you do not agree to these Terms then please do not access or otherwise use the Site. We reserve the right, at our discretion, to change these Terms at any time, which change will be effective thirty (30) days following posting the revised Terms on the Site. Your continued use of the Site thirty (30) days following such posting means you will accept those changes."
    },
    {
      title: '2. Subscription.',
      content: "<p><span>All subscriptions shall be automatically renewable at the end of their respective periods unless you notify us in writing that you do not wish to renew. We reserve the right to change prices and packages offered to subscribers from time to time provided, however, that in the event of an increase in price, your subscription will not be automatically renewed without providing you with at least 7 days advance written notice. If you choose to become a subscriber, you hereby agree and consent to receive email correspondence from us. Should you wish to stop receiving such emails, you will be required to de-register as a subscriber.</span></p><p><span>*Most subscriptions come with a 7-day free trial with the EXCEPTION of subscriptions that are offered with a <em><span class='underline'>discounted</span></em> promotional code.</span></p><p class='strong'>USE OF DISCOUNTED PROMO CODE / COUPON CODE WITH SUBSCRIPTION EXCEPTION:</p><p>Billing is immediate when a promotional code is redeemed for a discount offer. Use of this type of discounted offer forfeits a 7-day trial as the discount overrides the trial period listed at checkout.</p>"
    },
    {
      title: '3. Ability to Accept Terms.',
      content: "The Site is only intended for individuals aged 18 years or older. If you are under 18 years please do not use the Site."
    },
    {
      title: '4. Website Access.',
      content: 'We hereby grant you permission to access and use the Site provided that you comply with these Terms and applicable law and do not: (i) copy, distribute or modify any part of the Site without our prior written authorization; (ii) disrupt servers or networks connected to the Site; (iii) use or launch any automated system (including without limitation, "robots" and "spiders") to access the Site; and/or (iv) circumvent, disable or otherwise interfere with security-related features of the Site or features that prevent or restrict use or copying of any Content or that enforce limitations on use of the Site. We, in our sole discretion, have the right to terminate your access to the Site immediately, with or without cause. We reserve all rights not expressly granted in and to the Site.'
    },
    {
      title: '5. Intellectual Property Rights.',
      content: 'The content on the Site, including without limitation, the text, documents, descriptions, products, software, graphics, photos, sounds, videos, interactive features, and services, ("Content") and the trademarks, service marks and logos contained therein ("Marks"), are owned by or licensed to DOGTV. Content on the Site is provided to You "AS IS" for your information and personal use only and may not be used, copied, distributed, transmitted, broadcast, displayed, sold, licensed, de-compiled, or otherwise exploited for any other purposes whatsoever without our prior written consent. If you download or print a copy of the Content for personal use, you must retain all copyright and other proprietary notices contained therein. "DOGTV", the DOGTV logo, and other marks are Marks of DOGTV. All other trademarks, service marks, and logos used on our Site are the trademarks, service marks, or logos of their respective owners.'
    },
    {
      title: '6. Disclosure.',
      content: "We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process, subpoena or governmental request; (ii) enforce these Terms, including investigation of potential violations of it; (iii) detect, prevent, or otherwise address fraud, security or technical issues; (iv) respond to user support and/or contact requests; or (v) protect the rights, property or safety of DOGTV, our users or the public."
    },
    {
      title: '7. Links.',
      content: "The Site may contain links to third party websites that are not owned or controlled by us. We are not affiliated with, have no control over, and assume no responsibility for the content, privacy policies, or practices of any such third party websites. You expressly release us from any and all liability arising from your use of any third party website. Accordingly, we encourage you to be aware when you have left the Site and to read the terms and conditions and privacy policy of each third party website that you visit."
    },
    {
      title: '8. Information Description.',
      content: "We attempt to be as accurate as possible. However, we cannot and do not warrant that the Content is accurate, complete, reliable, current, or error-free. We reserve the right to make changes in or to the Content, or any part thereof, according to our sole judgment, without the requirement of giving any notice prior to or after making such changes. Your use of the Content is made solely at your own risk and responsibility."
    },
    {
      title: '9. Privacy Policy.',
      content: '<p>Our <strong>Privacy Policy </strong>can be found<strong> <a href="/privacy" rel="noopener">here.</a></strong></p>'
    },
    {
      title: '9.1. General.',
      content: 'We are committed to respecting your online privacy and recognize your need for appropriate protection and management of any Personally Identifiable Information (defined below) that you share with us. "Personally Identifiable Information" means any information that may be used, either alone or in combination with other information, to personally identify an individual, including, but not limited to, a name, email address and other contact information. Please refer to our privacy policy located on our website which is incorporated herein by reference.'
    },
    {
      title: '9.2. Access to the Site.',
      content: "We do not currently require you to provide Personally Identifiable Information in order to have access to general information available on the Site."
    },
    {
      title: '9.3. Subscription and Contact Information.',
      content: "If you choose to register for a premium service or contact us, you will be required to provide us with certain Personally Identifiable Information such as your name and an email address."
    },
    {
      title: '9.4. Cookies and Other Tracking Technologies.',
      content: "Some of our Site pages utilize cookies and other tracking technologies. Some cookies and other technologies may serve to recall Personally Identifiable Information previously indicated by a Site user. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them."
    },
    {
      title: '9.5. Use of Personally Identifiable Information.',
      content: "If you submit Personally Identifiable Information to us through the Site, then we may use such information to provide and improve our services to you, to contact you and to identify and authenticate your access to the Site’s services that you are authorized to access. We may transfer Personally Identifiable Information to our third party service and hosting providers, and/or our affiliated companies in order for them to perform business functions or services for us or on our behalf. Such information may be transferred to other countries around the world. We require that these parties agree to process such information in compliance with our privacy policy."
    },
    {
      title: '10.  Warranty.',
      content: 'The Site is provided on an "as is" and "as available" basis, and without warranties of any kind either express or implied. WE HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT ANY SERVICES WILL BE UNINTERRUPTED, ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. Applicable law may not allow the exclusion of certain warranties, so to that extent such exclusions may not apply.'
    },
    {
      title: '11. Limitation of Liability.',
      content: "TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE SHALL NOT BE LIABLE FOR ANY INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR INCIDENTAL DAMAGES OF ANY KIND ARISING OUT OF YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL OUR AGGREGATE LIABILITY FOR ANY DAMAGES UNDER THESE TERMS OR IN CONNECTION WITH THE SITE EXCEED THE TOTAL AMOUNT OF SUBSCRIPTION FEES, IF ANY, PAID BY YOU FOR USING THE SITE DURING THE SIX (6) MONTHS PRIOR TO BRINGING THE CLAIM."
    },
    {
      title: '12. Indemnity.',
      content: "You agree to defend, indemnify and hold harmless us and our affiliates and our respective officers, directors, agents, consultants and employees from any third party claims, damages, liabilities, costs, and expenses (including reasonable attorney's fees) arising from (i) your use of the Site; and/or (ii) your breach of these Terms."
    },
    {
      title: '13. Assignment.',
      content: "These Terms, and any rights granted hereunder, may not be transferred or assigned by you, but may be assigned by us without restriction."
    },
    {
      title: '14. General.',
      content: "<p>We reserve the right to discontinue or modify any aspect of the Site at any time. These Terms and the relationship between you and us shall be governed by and construed in accordance with the laws of the State of Israel, without reference to its conflict of laws rules. You and we agree to submit to the personal and exclusive jurisdiction of the courts located in Tel Aviv, Jaffa, Israel and waive any jurisdictional, venue, or inconvenient forum objections to such courts. This Section 14 and Sections 4 and 8 to 11 (inclusive) shall survive termination of these Terms. These Terms, together with any other legal notices published by us on the Site, shall constitute the entire agreement between you and us concerning the Site. If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect. No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and a party's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision. YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p><p>Please be advised that access to your purchased content is managed on our behalf, by our official provider Cleeng.&nbsp;Also, in most cases, the payment processing services in order to access the content are handled via our conditional access provider Cleeng B.V. The Cleeng User Terms and Conditions apply.</p><p>Last updated: January 2019</p>"
    }

  ];

  return <Accordion items={termItems} />;
};

export default Term;
