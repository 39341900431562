import { useEffect, useState } from 'react'
import { useAuth } from './useAuth'
import { useCleeng } from './useCleeng'
import { useOffers } from './useOffers'

export const useRegister = () => {
  const [consents, setConsents] = useState<IConsent[]>([])
  const [errors, setErrors] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const {
    fetchConsents,
    fetchLocale,
    registerCustomer,
    updateCustomerConsents,
  } = useCleeng()

  const { setCustomer, setLocale, setUserData } = useAuth()
  const { selectedOffer } = useOffers()

  const handleChange: React.FormEventHandler<HTMLFormElement> = (e) => {
    const password = e.currentTarget.password?.value || ''
    const confirm = e.currentTarget.confirm?.value || ''

    if (password !== confirm) {
      e.currentTarget.confirm.setCustomValidity('Passwords must match.')
    } else {
      e.currentTarget.confirm.setCustomValidity('')
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setLoading(true)
    setErrors([])

    const email = e.currentTarget.email?.value || ''
    const password = e.currentTarget.password?.value || ''
    const firstName = e.currentTarget.fname?.value || ''
    const lastName = e.currentTarget.lname?.value || ''

    const customerConsents: ICustomerConsent[] = consents.map((consent) => ({
      name: consent.name,
      version: consent.version,
      state: e.currentTarget[consent.name]?.checked ? 'accepted' : 'declined',
    }))

    const localeBody = await fetchLocale()
    const locale: ILocale = localeBody?.responseData || {
      country: 'US',
      currency: 'USD',
      locale: 'en US',
    }

    setLocale(locale)

    const customerBody = await registerCustomer({
      email,
      password,
      locale: locale.locale,
      country: locale.country,
      currency: locale.currency,
      firstName,
      lastName,
    })

    if (!customerBody.responseData) {
      setErrors(customerBody.errors)
      setLoading(false)

      return
    }

    const token = customerBody.responseData?.jwt

    const customerConsentsBody = await updateCustomerConsents(
      customerBody.responseData.customerId,
      customerConsents,
      token,
    )

    if (customerConsentsBody.errors?.length) {
      setErrors(customerConsentsBody.errors)
      setLoading(false)

      return
    }

    if (token?.length) {
      setCustomer(customerBody.responseData)
    }

    const customerConsentsPayload = customerConsents.reduce(
      (acc, curr, i) => ({
        ...acc,
        [`checkbox-${i}-name`]: curr.name,
        [`checkbox-${i}-version`]: curr.version,
        [`checkbox-${i}-accepted`]: curr.state === 'accepted',
      }),
      {},
    )

    setUserData({
      email,
      firstName,
      lastName,
      selectedOfferId: selectedOffer?.offerId || '',
      selectedOfferTitle: selectedOffer?.offerTitle || '',
      createdAt: new Date().toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
      }),
      ...customerConsentsPayload,
    })

    setLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const consentsBody = await fetchConsents()
      setConsents(consentsBody?.responseData?.consents || [])
      setLoading(false)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    consents,
    errors,
    loading,
    handleChange,
    handleSubmit,
  }
}
