import React from 'react';
import logo from '../assets/DOGTV_white_w_text_horizontal.png';
import { FaFacebookF, FaInstagram, FaTiktok, FaLinkedin, FaYoutube, FaPinterest } from 'react-icons/fa';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='nostickybtn' style={{ marginTop: 'auto', textAlign: 'center' }}>
      <div className="container-fluid">
        <div className='row stretched py-3 text-light align-items-center justify-content-center'>
          <div className="col-8 col-md-4 text-center text-md-start order-1 order-md-1">
            <a href="https://hello.dogtv.com/" rel="noopener noreferrer" className="no-underline">
              <img src={logo} alt="Logo" className="logo-img" />
            </a>
          </div>
          <div className="col-md-4 text-center order-3 order-md-2">
            <p className='fs-small'>
              &copy; {currentYear} DOGTV. All rights reserved.&nbsp;<span className='mobonly pb-3'><br/>
              <a href="https://watch.dogtv.com/" rel="noopener noreferrer" className="text-light">Sign-in</a>. &nbsp;
              <a href="/#start-now" rel="noopener noreferrer" className="text-light">Start For Free</a>.<br/>
              </span>
              <a href="/privacy" rel="noopener noreferrer" className="text-light">Privacy Policy</a>. &nbsp;
              <a href="/terms" rel="noopener noreferrer" className="text-light">Terms of Use</a>.&nbsp;
            </p>
          </div>
          <div className="socials col-md-4 text-center text-md-end py-3 order-2 order-md-3">
            {/* Social media links */}
            <a href="https://www.facebook.com/dogtv" target="_blank" rel="noopener noreferrer" className="mx-2 text-light no-underline">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/DOGTV/" target="_blank" rel="noopener noreferrer" className="mx-2 text-light no-underline">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@dogtvofficial" target="_blank" rel="noopener noreferrer" className="mx-2 text-light no-underline">
              <FaTiktok />
            </a>
            <a href="https://www.twitter.com/dogtv" target="_blank" rel="noopener noreferrer" className="mx-2 text-light no-underline">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M9.5,6.8l5.8-6.2h-1.4l-5,5.4l-4-5.4H0l6.2,8.2L0,15.5h1.4l5.4-5.9l4.4,5.9H16L9.5,6.8z M2.1,1.6h2.2l9.7,12.9h-2.2L2.1,1.6z"/>
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/dogtv" target="_blank" rel="noopener noreferrer" className="mx-2 text-light no-underline">
              <FaLinkedin />
            </a>
            <a href="https://www.youtube.com/dogtvworld" target="_blank" rel="noopener noreferrer" className="mx-2 text-light no-underline">
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
